@import "../node_modules/breakpoint-sass/stylesheets/breakpoint";
//@import "../node_modules/susy/sass/susy";
//@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);

// Globbing will always import files alphabetically. Optimally, all your
// components should be self-contained and explicit enough to not have any
// contained styles leak into unrelated components: The import order should not
// affect the result.
//
// If there are variables, functions or mixins that depend on each other and
// therefore need to be imported in a specific order you can import them
// directly. Variables-, function- or mixin providing partials should never
// output any styles themselves: Importing them multiple times to resolve
// dependencies shouldn't be a problem.
@import "variables/_1-base.scss";
@import "variables/_2-font.scss";
@import "variables/_3-layout.scss";
@import "variables/_button.scss";
@import "variables/_table.scss";
@import "variables/_tabs.scss";

//@import "background/**/*";
@import "helpers/_background-variant.scss";
@import "helpers/_background.scss";
@import "helpers/_box-emboss.scss";
@import "helpers/_box-sizing.scss";
@import "helpers/_breakpoint.scss";
@import "helpers/_buttons.scss";
@import "helpers/_center-block.scss";
@import "helpers/_clearfix.scss";
@import "helpers/_font-smooth.scss";
@import "helpers/_font.scss";
@import "helpers/_forms.scss";
@import "helpers/_gradients.scss";
@import "helpers/_hide-text.scss";
@import "helpers/_icon.scss";
@import "helpers/_image.scss";
@import "helpers/_keyframe.scss";
@import "helpers/_labels.scss";
@import "helpers/_layout.scss";
@import "helpers/_links.scss";
@import "helpers/_list.scss";
@import "helpers/_navigation.scss";
@import "helpers/_opacity.scss";
@import "helpers/_pagination.scss";
@import "helpers/_panels.scss";
@import "helpers/_preserve3d.scss";
@import "helpers/_progress-bar.scss";
@import "helpers/_reset-filter.scss";
@import "helpers/_resize.scss";
@import "helpers/_responsive-visibility.scss";
@import "helpers/_sass-inline-svg-data.scss";
@import "helpers/_sass-inline-svg.scss";
@import "helpers/_size.scss";
@import "helpers/_tab-focus.scss";
@import "helpers/_table-row.scss";
@import "helpers/_text.scss";
@import "helpers/_vendor-prefixes.scss";
@import "vendor/superfish/_default.scss";
@import "base/_00-fonts.scss";
@import "base/_01-reset.scss";
@import "base/_02-text.scss";
@import "base/_forms.scss";
@import "base/_global-classes.scss";
@import "base/_keyframes.scss";
@import "base/_links.scss";
@import "base/_lists.scss";
@import "base/_main.scss";
@import "base/_media.scss";
@import "base/_tables.scss";
@import "layouts/_00-layout.scss";
@import "layouts/_01-flex.scss";
@import "layouts/_atomic-grid.scss";
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
  }
  body {
    font-size: 12pt;
    background: #fff;
    color: #000;
  }
  a,
  a:visited {
    text-decoration: underline;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }
  h1 {font-size: 24pt}

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Bootstrap specific changes start
  //
  // Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
  // Once fixed, we can just straight up remove this.
  select {
    background: #fff !important;
  }
  //.pane-page-header-image,
  //.pane-page-header-image .panel-region-main{
  //  max-height: 100pt;
  //}
  //.pane-page-header-image .panel-region-main * {
  //  color: white;
  //}
  header {
    background: white;
  }
  .branding {
    width: 100%;
  }
  //.panel-region-hero h1 {
  //  color: #000;
  //}
  .panel-region-hero .pane-node-field-image,
  .overlay,
  nav,
  .header-preface-wrapper,
  .logo-icon,
  .region-header,
  .panel-region-left-aside,
  .panel-region-right-aside,
  footer,
  #mini-panel-exhibit_header,
  .b-media-wrapper{
    display: none;
  }
  .branding-icon-wrapper span {display: inline-block}

  .branding-icon-wrapper .logo-icon {
    background: url("../images/logos/si-logo-black.png") no-repeat 50% 50%;
    height: 59px;
    width: 59px;
    color: #000;
    .site-name,
    .si-name {
      padding-left: 0;
    }
  }
  .btn,
  .dropup > .btn {
    > .caret {
      border-top-color: #000 !important;
    }
  }
  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }
  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }



  .masonry-grid {
    height: auto !important;
    @include clearfix;
    &:before,
    &:after{
      flex-basis:0;
      order: 1;
    }
      display: flex;
      flex-wrap: wrap;

  }
  .grid-item {
    @include clearfix;
    flex: 1 0 auto;
    position: static !important;
    top: auto !important;
    left: auto !important;
    border: 1px solid #8e97a0 !important;
    margin-bottom: 2%;
    padding: 12px;
    float: left;
    width: 32%;
    margin-left: 2%;
    &:nth-child(3n+1) {
      margin-left: 0;
      clear:left;
    }
    img {
      width: 25% !important;
      height: auto !important;
      float: left;
      margin-right: 5%;
    }
    .b-text-wrapper {
      padding: 0;
      width: 70%;
      float: right;
    }
  }

}


